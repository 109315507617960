import { postWithJson } from '../../assets/js/serviceUtil'

//获取页面图片
export const getBanner = (params) => postWithJson('/api/sys/config/prop/detail',params)

//获取招聘列表
export const getJobList = (params) => postWithJson('/api/sys/webjob/paging',params)

//投递简历
export const sendResume = (params) => postWithJson('/api/sys/webApplicants/save',params)
