<template>
  <div>
    <file-upload-local
      v-if="conf.provider==='local'"
      v-model="fileUrl"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType"
      :showFileList="showFileList"
      :drag="drag" />

    <file-upload-oss
      v-if="conf.provider==='aliyun'"
      v-model="fileUrl"
      :accept="accept"
      :tips="tips"
      :compress="compress"
      :limit="limit"
      :list-type="listType"
      :showFileList="showFileList"
      :drag="drag" />

    <file-upload-qiniu
      v-if="conf.provider==='qiniu'"
      v-model="fileUrl"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType"
      :showFileList="showFileList"
      :drag="drag" />

    <file-upload-cos
      v-if="conf.provider==='qcloud'"
      v-model="fileUrl"
      :accept="accept"
      :tips="tips"
      :limit="limit"
      :compress="compress"
      :list-type="listType"
      :showFileList="showFileList"
      :drag="drag" />

  </div>
</template>

<script>

import { fetchDetail } from '@/api/sys/config/prop'
import FileUploadOss from './oss'
import FileUploadLocal from './local'
import FileUploadQiniu from './qiniu'
import FileUploadCos from '@/components/FileUpload/cos'

export default {
  name: 'FileUpload',
  components: { FileUploadCos, FileUploadQiniu, FileUploadLocal, FileUploadOss },
  props: {
    value: String,
    accept: {
      type: String,
      default: '*'
    },
    tips: String,
    listType: {
      type: String,
      default: 'picture'
    },
    compress: {
      type: Number,
      default: 0
    },
    limit:{
      type: Number,
      default: 1
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    drag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      conf: '',
      fileUrl: ''
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.fillValue()
      }
    },

    // 检测查询变化
    fileUrl: {
      handler(val) {
        // 无值则表示清除
        this.$emit('input', val)
        if (val) {
          this.$emit('success', val)
        }
      }
    }
  },
  mounted() {
    // 查找配置
    this.fetchConfig()
  },

  created() {
    this.fillValue()
  },

  methods: {

    // 查找配置情况
    fetchConfig() {
      fetchDetail('upload', '').then(res => {
        this.conf = res.data
      })
    },

    fillValue() {
      this.fileUrl = this.value
    }

  }
}
</script>

<style scoped>

.el-upload-list--picture .el-upload-list__item-thumbnail{
  background: #969696 !important;
}
</style>
