import { Message } from 'element-ui'
import { buildDir } from '@/utils/file-utils'
import { upload } from '@/utils/request'
import { fetchDetail } from '@/api/sys/config/prop'
import { fetchToken as ossToken } from '@/api/sys/config/oss'
import { fetchToken as cosToken } from '@/api/sys/config/cos'
import { fetchToken as qiniuToken } from '@/api/sys/config/qiniu'
import { compressAccurately } from 'image-conversion'

/**
 * 文件上传入口
 * @param file
 * @param data
 * @returns {Promise<unknown>}
 */
export function uploadFile(file) {
  return new Promise((resolve, reject) => {
    fetchDetail('upload', '').then(res => {
      // 上传配置
      const cfg = res.data

      // 本地文件上传
      if (cfg.provider === 'local') {
        upload('/api/common/file/upload', file).then(res => {
          resolve(res.data.url)
        }, err => {
          reject(err)
        })
      }

      // OSS文件上传
      if (cfg.provider === 'aliyun') {
        ossUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }

      // 七牛云文件上传
      if (cfg.provider === 'qiniu') {
        qiniuUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }

      // 腾讯云文件上传
      if (cfg.provider === 'qcloud') {
        cosUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }
    })
  })
}

// OSS上传
export function ossUpload(file) {
  return new Promise((resolve, reject) => {
    // 获得上传令牌
    ossToken().then(res => {
      const conf = res.data
      const dataObj = {
        'policy': res.data.policy,
        'OSSAccessKeyId': res.data.accessId,
        'success_action_status': 200,
        'signature': res.data.signature,
        'key': buildDir() + getFileExt(file)
      }
      upload(conf.host, file, dataObj).then(() => {
        // 保存截图
        const url = conf.url + dataObj.key
        resolve(url)
      })
    }).catch(err => {
      Message.error(`获取OSS令牌失败： ${err} `)
      reject(err)
    })
  })
}

/**
 * 腾讯云文件上传
 * @param file
 * @returns {Promise<unknown>}
 */
export function cosUpload(file) {
  return new Promise((resolve, reject) => {
    // 获得上传令牌
    cosToken().then(res => {
      const conf = res.data
      // 使用临时秘钥初始化上传对象
      // eslint-disable-next-line no-undef
      const cos = new COS({
        SecretId: res.data.tmpSecretId,
        SecretKey: res.data.tmpSecretKey,
        XCosSecurityToken: res.data.sessionToken
      })

      const key = buildDir() + getFileExt(file)

      cos.sliceUploadFile({
        Bucket: conf.bucket,
        Region: conf.region,
        Key: key,
        StorageClass: 'STANDARD',
        Body: file,
        onTaskReady: function() {
          // that.taskId = taskId
        },
        onProgress: function() {
        }
      }, function(err, data) {
        // 保存数据
        const url = conf.url + data.Key
        resolve(url)
      })
    }).catch(err => {
      Message.error(`获取COS上传令牌失败： ${err} `)
      reject(err)
    })
  })
}

/**
 * 七牛云文件上传
 * @param file
 * @returns {Promise<unknown>}
 */
export function qiniuUpload(file) {
  return new Promise((resolve, reject) => {
    // 获得上传Token
    qiniuToken().then(res => {
      const data = res.data

      const dataObj = {
        'token': data.token,
        'key': buildDir() + getFileExt(file)
      }

      upload(data.endpoint, file, dataObj).then(() => {
        // 保存数据
        const url = data.url + dataObj.key
        resolve(url)
      })
    }).catch(err => {
      Message.error(`获取七牛令牌失败： ${err} `)
      reject(err)
    })
  })
}

/**
 * 获取文件后缀
 * @param file
 * @returns {string}
 */
export function getFileExt(file) {
  const name = file.name
  const ext = name.split('.').pop()
  if (ext != null && ext !== '') {
    return '.' + ext.toLowerCase()
  }
  return ''
}

/**
 * 进行图像压缩
 * @param file
 * @returns {string}
 */
export async function compressImage(file, compress) {
  return await new Promise((resolve) => {
    // 超出多少K进行压缩
    const kb = file.size / 1024

    console.log('图片压缩前：' + kb + 'K')

    if (!compress || compress >= kb) {
      resolve(file)
      return
    }

    // 压缩至500K，宽度800
    const config = {
      size: 500,
      width: 800,
      type: file.type
    }

    compressAccurately(file, config).then(res => {
      console.log('图片压缩后：' + (res.size / 1024) + 'K')
      resolve(res)
    })
  })
}
