<template>
  <div class="job">
    <div class="recruit-item flex">
      <div v-if="info">
        <div class="job-name">{{info.jobName}}</div>
        <div class="job-info flex">
          <span style="margin-right: 80px">
            <i class="el-icon-location-outline"></i>
            {{info.jobAddr}}
          </span>
          <span>
            <i class="el-icon-time"></i>
            {{formateDate(info.createTime)}}
          </span>
        </div>
      </div>
      <div>
        <span class="recruit-btn" @click="submitJob(info.id)">立即投递</span>
        <i :class="isDetail ? 'el-icon-arrow-down':'el-icon-arrow-right'" @click="isDetail=!isDetail"></i>
      </div>
    </div>
    <div class="recruit-detail" v-show="isDetail">
      <div>
        <p class="job-info-title">职责</p>
        <div v-html="info.jobZhize">
        </div>
      </div>
      <div>
        <p class="job-info-title">任职要求</p>
        <div v-html="info.jobYaoqiu">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    info: {
      type: Object,
      default: null
    } //职位信息
  },
  data() {
    return {
      isDetail: false
    }
  },
  methods: {
    submitJob(id){
      //投递简历
      this.$emit('submit',id)
    },
    formateDate(date) {
      return moment(date).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="less" scoped>
.recruit-item{
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.08); 
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}
.job{
  margin-bottom: 10px;
}
.job-name{
  font-size: 20px;
  font-weight: 600;
  color: #333;
  line-height: 28px;
  margin-bottom: 10px;
}
.job-info{
  font-size: 16px;
  text-align: left;
  color: #666;
  line-height: 22px;
}
.recruit-btn{
  display: inline-block;
  width: 104px;
  height: 40px;
  background: #1c55b2;
  text-align: center;
  color: #fff;
  line-height: 40px;
  margin-right: 20px;
  cursor: pointer;
}
.recruit-detail{
  padding: 0 20px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
}
.job-info-title{
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 30px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  margin-top: 30px;
}
.job-info-title::before{
  content: ' ';
  width: 4px;
  height: 20px;
  background: #1c55b2;
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
}
</style>