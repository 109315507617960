import { postWithJson } from '@/assets/js/serviceUtil'

export function fetchDetail(type, provider) {
  if (!provider) {
    provider = ''
  }

  return postWithJson('/api/sys/config/prop/detail', { type: type, provider: provider })
}

export function saveData(data) {
  return postWithJson('/api/sys/config/prop/save', data)
}
