import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  // 移除本地存储残留
  localStorage.removeItem(TokenKey)

  // 移除cookie
  return Cookies.remove(TokenKey)
}
