<template>
  <div class="recruit-page">
    <div class="banner">
      <el-image :src="bannerUrl">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>图片正在加载
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <div class="recruit-list">
      <job v-for="item in jobList"
        :key="item.id"
        :info="item"
        @submit="sendResume"></job>
      <div class="pagination-box flex" v-if="total">
        <el-pagination
          @size-change="getJobs"
          @current-change="getJobs"
          :current-page.sync="pageDto.current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageDto.size"
          layout="sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
    title="投递简历"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose">
      <el-form :model="jobForm" :rules="rules" ref="jobForm" label-width="80px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="jobForm.name" style="width:360px"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="jobForm.phone" style="width:360px"></el-input>
        </el-form-item>
        <el-form-item label="上传附件" prop="files">
          <file-upload v-model="jobForm.files" 
            :limit="1" 
            :compress="3000" 
            tips="支持DOC、DOCX、PDF、JPG、PNG格式附件"
            :drag="true"
            listType="text"
            accept=".doc,.docx,.pdf,.jpg,.png" />
        </el-form-item>
        <el-form-item>
          <button @click="submitForm('jobForm')" class="send-btn flex">
            <img src="../../assets/images/icon/submit.png">提交投递
          </button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Job from './components/job.vue'
import { getBanner, getJobList,sendResume } from './request'
import FileUpload from '@/components/FileUpload'

export default {
  components: {
    Job,
    FileUpload
  },
  data() {
    return {
      dialogVisible: false,
      jobForm: {
        name: '',
        phone: '',
        files: null,
        fileName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator (rule, value, callback) {
              const emailPrefixRegx = /^1([2-9])\d{9}$/;
              if (!emailPrefixRegx.test(value)) {
                  callback(new Error('手机号码格式错误'));
              } else {
                  callback();
              }
            },
            trigger: 'blur'
          }
        ],
        files: [
          { required: true, message: '请上传附件', trigger: 'blur' }
        ]
      },
      jobList: [],
      pageDto: {
        current: 1,
        size: 10,
      },
      bannerUrl: '',
      total: 0, // 岗位总数
      jobId: null, //当前投递简历id
    }
  },
  mounted() {
    this.fetchImgUrl()
    this.getJobs()
  },
  methods: {
    handleClose(){
      this.dialogVisible = false
      this.jobForm = {
        name: '',
        phone: '',
        files: null,
      }
    },
    sendResume(id) {
      this.dialogVisible = true
      this.jobId = id
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(valid)
          const params = {
            ...this.jobForm,
            job_id: this.jobId
          }
          sendResume(params).then(res => {
            if(res.code === 0) {
              this.$message({
                message: '岗位投递成功！',
                type: 'success'
              });
              this.dialogVisible = false
              this.jobId = ''
            }
          })
        }
      })
    },
    async fetchImgUrl() {
      let res = await getBanner({
        type: 'recruit',
        provider: 'recruit'
      })
      if(res.data.data){
        const imgUrl = JSON.parse(res.data.data)
        this.bannerUrl = imgUrl.bannerUrl
      }
    },
    getJobs() {
      getJobList({
        ...this.pageDto
      }).then(res => {
        if(res.data) {
          this.jobList = res.data.records.map(item => {
            return {
              ...item,
              jobYaoqiu: item.jobYaoqiu ? item.jobYaoqiu.replaceAll('\n','<br/>') : '',
              jobZhize: item.jobZhize ? item.jobZhize.replaceAll('\n','<br/>') : ''
            }
          })
          this.total = res.data.total
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.banner{
  margin-bottom: 40px;
  img{
    width: 100%;
  }
}
.recruit-list{
  padding: 0 10%;
  margin: 20px 0;
}
.send-btn{
  width: 160px;
  height: 40px;
  background: #1c55b2;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  img{
    width: 16px;
    margin-right: 5px;
  }
}
.pagination-box{
  justify-content: flex-end;
  margin-top: 10px;
}
</style>