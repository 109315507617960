<template>
  <div class="content">
    <el-upload
      :action="conf.host"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :drag="listType!=='picture' && drag"
      :accept="accept"
      :limit="limit===1?2:limit"
      :file-list="fileList"
      :list-type="listType"
      :data="dataObj"
      :show-file-list="showFileList"
      :on-preview="handlePreview"
    >

      <el-button v-if="listType==='picture' || !drag" size="small" type="primary">点击上传</el-button>

      <i v-if="listType!=='picture' && drag" class="el-icon-upload" />
      <div v-if="listType!=='picture' && drag" class="el-upload__text" style="line-height: 22px;">
        <div>将文件拖到此处，或<em>点击上传</em></div>
        <div style="font-size:12px;color:#999">{{ tips }}</div>
      </div>
      <!-- <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div> -->
    </el-upload>
  </div>
</template>

<script>
import { buildDir } from '@/utils/file-utils'
import { fetchToken } from '@/api/sys/config/oss'
import { compressImage } from '@/utils/upload'

export default {
  name: 'FileUploadOss',
  props: {
    value: String,
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    },
    compress: {
      type: Number,
      default: 0
    },
    showFileList: Boolean,
    drag: Boolean,// 允许拖拽
  },
  data() {
    return {
      fileList: [],
      multiple: false,
      dataObj: {}, // 文件及授权信息
      conf: {
        host: ''
      }
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.fillValue()
      }
    }
  },

  mounted() {
    this.fetchConfig()
  },

  created() {
    this.fillValue()
  },
  methods: {

    // 填充数据
    fillValue() {
      this.fileList = []
      this.fileUrl = this.value
      if (this.fileUrl) {
        if(this.limit == 1){
          this.fileList = [{ name: this.fileUrl, url: this.fileUrl }]
        }else {
          const lsUrl = this.fileUrl.split(',');
          for (let i = 0; i < lsUrl.length; i++) {
            if (lsUrl[i]) {
              this.fileList.push({name: lsUrl[i], url: lsUrl[i]})
            }
          }
        }

      }
    },

    // 获取基础配置
    fetchConfig() {
      // 获得上传令牌
      fetchToken().then(res => {
        this.conf = res.data
        this.dataObj['policy'] = res.data.policy
        this.dataObj['OSSAccessKeyId'] = res.data.accessId
        this.dataObj['success_action_status'] = 200
        this.dataObj['signature'] = res.data.signature
      }).catch(err => {
        this.$message.error(`获取上传令牌失败： ${err} `)
      })
    },

    // 文件超出个数限制时的钩子
    handleExceed() {
      // 替换
      if (this.limit > 1) {
        this.$message.warning(`每次只能上传 ${this.limit} 个文件`)
      }
    },
    // 删除文件之前的钩子
    beforeRemove() {
      return this.$confirm(`确定移除文件吗？`)
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      let url = ''
      if(fileList){
        for (let i = 0; i< fileList.length ; i++){
          url += fileList[i]['url'] + ','
        }
        url = url.substring(0,url.length-1)
      }
      this.$emit('input', url)
      this.fileList = fileList
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      this.fileList = fileList

      if(this.limit == 1) {
        this.$emit('input', this.conf.url + this.dataObj.key)
      } else {
        if(this.value){
          this.$emit('input', this.value + ',' + this.conf.url + this.dataObj.key)
        }else {
          this.$emit('input', this.conf.url + this.dataObj.key)

        }
      }
    },
    // 文件上传前的校验
    beforeUpload(file) {
      const fileName = this.buildName(file)
      this.dataObj['key'] = fileName
      return compressImage(file, this.compress)
    },

    // 重命名文件
    buildName(file) {
      const temporary = file.name.lastIndexOf('.')
      const fileNameLength = file.name.length
      const fileFormat = file.name.substring(
        temporary + 1,
        fileNameLength
      )
      return buildDir() + '.' + fileFormat
    },
    // 文件预览
    handlePreview(file) {
      if(this.listType != 'text'){
        return
      }
      if(file.url) {
        window.open(file.url)
      }
    }
  }
}
</script>
