<template>
  <div class="content">
    <el-upload
      v-model="fileUrl"
      :action="uploadUrl"
      :accept="accept"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :drag="listType!=='picture' && drag"
      :limit="limit===1?2:limit"
      :headers="header"
      :file-list="fileList"
      :list-type="listType"
      :show-file-list="showFileList"
      :on-preview="handlePreview"
    >

      <el-button v-if="listType==='picture' || !drag" size="small" type="primary">点击上传</el-button>

      <i v-if="listType!=='picture' && drag" class="el-icon-upload" />
      <!-- <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div> -->
      <div v-if="listType!=='picture' && drag" class="el-upload__text" style="line-height: 22px;">
        <div>将文件拖到此处，或<em>点击上传</em></div>
        <div style="font-size:12px;color:#999">{{ tips }}</div>
      </div>
    </el-upload>

  </div>

</template>

<script>

import { getToken } from '@/utils/auth'
import { compressImage } from '@/utils/upload'

export default {
  name: 'FileUploadLocal',
  props: {
    value: String,
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    },
    compress: {
      type: Number,
      default: 0
    },
    showFileList: Boolean,
    drag: Boolean,// 允许拖拽
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/common/file/upload',
      fileList: [],
      fileUrl: '',
      header: {}
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.fillValue()
      }
    }
  },

  created() {
    this.fillValue()
    this.header = { token: getToken() }
  },

  methods: {

    fillValue() {
      this.fileList = []
      this.fileUrl = this.value
      if (this.fileUrl) {
        if(this.limit == 1){
          this.fileList = [{ name: this.fileUrl, url: this.fileUrl }]
        }else {
          const lsUrl = this.fileUrl.split(',');
          for (let i = 0; i < lsUrl.length; i++) {
            if (lsUrl[i]) {
              this.fileList.push({name: lsUrl[i], url: lsUrl[i]})
            }
          }
        }

      }
    },
    beforeUpload(file) {
      return compressImage(file, this.compress)
    },

    // 文件超出个数限制时的钩子
    handleExceed() {
      // 替换
      if (this.limit > 1) {
        this.$message.warning(`每次只能上传 ${this.limit} 个文件`)
      }
    },
    // 删除文件之前的钩子
    beforeRemove() {
      return this.$confirm(`确定移除文件吗？`)
    },

    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      let url = ''
      if(fileList){
        for (let i = 0; i< fileList.length ; i++){
          url += fileList[i]['url'] + ','
        }
        url = url.substring(0,url.length-1)
      }
      this.$emit('input', url)
      this.fileList = fileList
    },

    // 文件上传成功时的钩子
    handleSuccess(response,file, fileList) {
      this.fileList = fileList
      if(this.limit == 1) {
        this.$emit('input', response.data.url)
      }else {
        if(this.value){
          this.$emit('input', this.value + ',' + response.data.url)
        }else {
          this.$emit('input', response.data.url)
        }
      }
    },
    // 文件预览
    handlePreview(file) {
      if(this.listType != 'text'){
        return
      }
      if(file.url) {
        window.open(file.url)
      }
    }
  }
}
</script>

